@import 'variables';

.admin-pill-nav {
  width: 250px;
  flex-shrink: 0;
  margin-right: 30px;

  .nav-item {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    cursor: pointer;
    text-transform: capitalize;
    white-space: nowrap;
    margin-bottom: 5px;
    color: mat-color($app-foreground, text);
    position: relative;
    border-left: 4px solid transparent;
    border-radius: 4px;

    &:hover {
      background-color: mat-color($app-background, hover);
    }

    &.selected {
      background-color: mat-color($app-accent, emphasis);
      border-color: mat-color($app-accent, default);
      font-weight: 700;
    }
  }

  .name {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .actions {
    color: mat-color($app-foreground, icon);
    margin-left: auto;
  }

  .mat-icon-button {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
}

button.admin-pill-nav-mobile-selector {
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 4px;
  border: 1px solid mat-color($app-foreground, divider);
  padding: 15px;
  text-transform: capitalize;
  background-color: mat-color($app-background, card);

  .mat-icon {
    margin-left: auto;
  }
}

.admin-pill-nav-menu.mat-menu-panel {
  min-width: 200px;


  .mat-menu-item {
    text-transform: capitalize;
  }

  .selected {
    color: mat-color($app-accent, default);
  }
}
