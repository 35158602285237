@import 'common-imports';

button.more-less-button {
  padding-left: 5px;
  //color: mat-color($app-accent, default);
  font-weight: 700;
}

.play-button-animation.mat-mini-fab,
.play-button-animation.mat-fab {
  transition: background 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
    box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1),
    transform 0.3s cubic-bezier(0, 0, 0, 2.41);

  &:hover {
    transform: scale(1.2);
  }
}


::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  background: rgb(46, 59, 66);
}

::-webkit-scrollbar-thumb {
  background-color: rgb(243, 70, 54);
  border-radius: 10px;
  border: none;

}

.star-full {
  background-color: #f3c121 !important;
}

.star-half {
  background-color: #f3c121 !important;
}

.star-empty {
  background-color: #f3c121 !important;
}

// @media screen and (max-width: 768px) {
//   .hot-topics .section1 .title {
//     font: size 10px;
//   }
// }
