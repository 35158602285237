@import 'variables';

.social-icons {
  &:empty {
    display: none;
  }

  .social-icon {
    width: auto;
    height: auto;
    line-height: 32px;
    margin-right: 12px;
    border-radius: 16px;
    text-align: center;

    &.facebook {
      // background-color: $facebook-color;
      // color: #fff;
    }

    &.twitter {
      background-color: $twitter-color;
      color: #fff;
    }

    &.youtube {
      background-color: $youtube-color;
      color: #fff;
    }

    &.instagram {
      background-color: $instagram-color;
      color: #fff;
    }

    &.linkedin {
      background-color: $linkedin-color;
      color: #fff;
    }

    &.google {
      // background-color: $google-color;
      // color: #fff;
    }

    &.mail {
      background-color: $social-icon-mail-color;
      color: #fff;
    }

    &.link {
      background-color: $social-icon-link-color;
      color: #fff;
    }

    .mat-icon {
      width: 18px;
      height: 17px;
      line-height: 18px;
      margin: 0 auto;
    }
  }
}

