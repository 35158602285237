@import 'variables';

body,
html {
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  position: relative;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  touch-action: manipulation;
}

body {
  font-family: $font-family !important;
  color: mat-color($app-foreground, text);
  // background-color: mat-color($app-background, dialog);
  line-height: 1.42857143;
  font-size: 1.6rem;
  margin: 0 auto;
  background-color: rgb(38, 50, 56);
  font-weight: 400;
  max-width: 1920px;
  margin: 0 auto;
  
}


//box-sizing
body {
  box-sizing: border-box;

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    // appearance: none;
    // background-color: #fff !important;
    background-image: url('/client/assets/images/play-down.png');
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 14px 12px;
  }
}

.be-fullscreen-overlay-scrollblock {
  width: 100%;
  position: fixed;
}

.button-red {
  background-color: #b13d37;
  color: #fff;
  margin-left: 44%;
  padding: 4px;
  width: 123px;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #6c586a inset !important;
  -webkit-text-fill-color: white !important;
  border: none !important;
}
